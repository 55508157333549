import React from 'react';
import { Link,Image } from 'gatsby';
import styled from '@emotion/styled';
import Headroom from 'react-headroom';
import logo from '../../static/logo/logo.png';

const StyledLink = styled(Link)`
  display: flex;
  font-weight: 700;
  align-items: center;
`;
const Logo = styled.img`
@media (max-width: 768px) {
  display:none;
}
`
const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
  font-family: ${props => props.theme.fontFamily.body};
  font-weight: 500;
  font-size: 1.1rem;
  @media (max-width: 420px) {
    font-size: 0.5rem;
  }
  @media (max-width: 550px) {
    font-size: 0.8rem;
  }
  
  align-items: center;
  a {
    color: ${props => props.theme.colors.white.base};
    margin-left: 2rem;
    transition: all ${props => props.theme.transitions.default.duration};
    &:hover {
      color: ${props => props.theme.colors.white.grey};
    }
  }
`;

//<Link to="/ratings">Ratings</Link>
const NavBar = () => (
  <Headroom calcHeightOnResize disableInlineStyles>
    <StyledLink to="/">
      <Logo src={logo} alt="Logo" />
    </StyledLink>
    <Nav>
      <Link to="/">Home</Link>      
      <Link to="/puzzle">Puzzle</Link>
      <Link to="/links">Links</Link>
      <Link to="/news">News</Link>
      <Link to="/about">About</Link>
      
    </Nav>
  </Headroom>
);

export default NavBar;
