import React from 'react';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const Wrapper = styled.header`
  -webkit-clip-path: polygon(100% 0, 0 0, 0 70%, 50% 100%, 100% 70%);
  //clip-path: polygon(100% 0, 0 0, 0 70%, 50% 100%, 100% 70%);
  clip-path: ellipse(100% 90% at 50% 0%);
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    -webkit-clip-path: polygon(100% 0, 0 0, 0 90%, 50% 100%, 100% 90%);
    // clip-path: polygon(100% 0, 0 0, 0 90%, 50% 100%, 100% 90%);
    clip-path: ellipse(100% 80% at 50% 0%);
  }
  background: ${props => props.theme.gradient.rightToLeft};
  height: 300px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    height: 250px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    height: 175px;
    div h2 { margin-bottom:0.1rem;}
  }

  position: relative;
  overflow: hidden;
`;

const Text = styled.div`
  color: ${props => props.theme.colors.white.base};
  z-index: 0;
  position: absolute;
  top: 60%;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    top: 50%;
  }
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  max-width: ${props => props.theme.layout.base};
  padding: 0 2rem;
  margin-bottom: 3rem;
  align-items: center;
`;

const Subtitle = styled.p`
  max-width: 650px;
  color: ${props => props.theme.colors.white.light};
`;

// <h3>{date}</h3>
const Header = function ({ children, title, date, cover }){
  console.log("cover now ",cover)
  if (!cover)
  {
    return (
      <Wrapper>
        <Text>
          <h2>{title}</h2>
          <span>Tuesdays at 7 pm - Hunting Lodge, Whitley Bay</span>
          

          {children && <Subtitle>{children}</Subtitle>}
        </Text>
      </Wrapper>
    )
  }
  else
  {
        return (
        <Wrapper>
          <Img  fluid={cover} />
          <Text>
            <h2>{title}</h2>
            
         

            {children && <Subtitle>{children}</Subtitle>}
          </Text>
        </Wrapper>
      )
        }
  }

export default Header;

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  cover: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

Header.defaultProps = {
  children: false,
  cover: false,
  date: false,
  title: false,
};
